<template>
  <div>
    <portal to="page-top-title">New Blacklist</portal>
    <portal to="page-top-right"></portal>

    <box-form action="create" data-prop="formData" url="/blacklists" redirect="blacklists-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input>
            <form-group-file name="file" :model="formData.file" accept=".csv" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="md5_list" :model="formData.md5_list"/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupFile from '@/views/components/form/FormGroupFile'
import Form from '@/modules/utils/Form'

export default {
  name: 'BlacklistNew',
  metaInfo () {
    return { title: 'Blacklists | New' }
  },
  components: {
    BoxForm,
    FormGroupCheck,
    FormGroupInput,
    FormGroupFile
  },
  data () {
    return {
      formData: {
        name: Form.types.input(),
        file: Form.types.file(),
        md5_list: Form.types.boolean()
      }
    }
  }
}
</script>
